@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.login-form {
  position: absolute;
  top: 10%;
  width: 500px;
}

.header-link:hover {
  color: #333;
  /* Customize the color on hover */
}

/* MainLayout.css (or your custom stylesheet) */
#sidebar-wrapper {
  min-height: 100vh;
  padding: 0;
  /* */
}

/* Your CSS file */
#sidebar {
  background-color: rgb(90, 69, 172);
  color: white;
  padding-top: 20px;
  min-height: 100vh;
  /* Set the sidebar height to fill the entire viewport */
}

.sidebar-header {
  padding: 10px;
  text-align: center;
}

.sidebar-header h3 {
  margin: 0;
}

.list-unstyled {
  padding-left: 0;
}

.list-unstyled li {
  padding: 10px;
}

.list-unstyled li a {
  color: #fff;
  text-decoration: none;
  display: flex;
  justify-content: center;
  align-items: center;
}

.list-unstyled li.active a {
  background-color: #ffffff;
  color: black !important;
  width: 200px;
  border-radius: 20px;
  height: 45px;
  position: absolute;
  left: 25px;
}

/* Responsive styling for smaller screens */
@media (max-width: 768px) {
  #sidebar {
    position: fixed;
    top: 0;
    left: -250px;
    /* Hide the sidebar initially */
    width: 250px;
    transition: all 0.3s;
  }

  #sidebar.active {
    left: 0;
    /* Show the sidebar when active class is applied */
  }
}


#content-wrapper {
  padding: 20px;
  /* margin-left: 200px;  */
}


.pagination-wrapper {
  position: absolute;
  right: 0;
  display: flex;
  align-items: center;
  padding: 8px;
}

.pagination-wrapper button {
  margin: 0 5px;
}

.pagination-wrapper span {
  margin: 0 10px;
}

.header {
  background: rgb(90, 69, 172)
}